import axios, { AxiosError } from 'axios'

import { AxiosInstance, InternalAxiosRequestConfig, RequestConfig, AxiosResponse } from './types'
import qs from 'qs'
import singleMessage from '@/utils/singleMessage'
import { useUserStore } from '@/store/modules/user'
import { getDeviceId } from '@/utils/util'
import { useStorage } from '@/hooks/web/useStorage'
import { useI18n } from '@/hooks/web/useI18n'

let globalUrl = ''

const requestInterceptors = (config: InternalAxiosRequestConfig) => {
  if (config.method === 'post' && config.params) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    config.data = qs.stringify(config.params)
    delete config.params
  }
  if (config.method === 'get' && config.params) {
    let url = config.url as string
    url += '?'
    const keys = Object.keys(config.params)
    for (const key of keys) {
      if (config.params[key] !== void 0 && config.params[key] !== null) {
        url += `${key}=${encodeURIComponent(config.params[key])}&`
      }
    }
    url = url.substring(0, url.length - 1)
    delete config.params
    config.url = url
  }

  const { getStorage } = useStorage()
  let lang = getStorage('lang') || 'zh-CN'
  if (lang == 'zh-CN') {
    lang = 'zh-CN'
  }
  if (lang == 'en') {
    lang = 'en-US'
  }
  if (lang == 'th') {
    lang = 'th-TH'
  }
  if (lang == 'km') {
    lang = 'km-KH'
  }
  if (lang == 'id') {
    lang = 'id-ID'
  }
  if (lang == 'es') {
    lang = 'es-ES'
  }
  if (lang == 'ru') {
    lang = 'ru-RU'
  }
  if (lang == 'kk') {
    lang = 'kk-KZ'
  }
  config.headers['Accept-Language'] = lang
  config.headers.vc = process.env.PACKAGE_VERSION
  config.headers.dt = getDeviceId()

  const token = useUserStore().userInfo.token
  if (token) {
    config.headers.Authorization = token
  }
  globalUrl = config.url as string
  return config
}

const responseInterceptors = (response: AxiosResponse<any>) => {
  const { t } = useI18n()
  if (response?.config?.responseType === 'blob') {
    // 如果是文件流，直接过
    return response
  }
  const data: any = response?.data
  // const errCode: any = data?.errCode
  const errMsg = data?.errMsg || t('10608')
  // if (errCode) {
  //   errMsg += `(${errCode})`
  // }
  if (data && data.code == 1) {
    singleMessage.error(errMsg)
  }

  return response.data
}

const resErrorInterceptors = (error: AxiosError) => {
  const { t } = useI18n()
  const status = error.response?.status || 500
  const data: any = error?.response?.data
  // const errCode: any = data?.errCode
  const errMsg = data?.errMsg || t('10608')
  // if (errCode) {
  //   errMsg += `(${errCode})`
  // }
  if (globalUrl.indexOf('/auth/token/logout') > 0) {
    return
  }
  if (status === 401) {
    singleMessage.warning(t('10602'))
    useUserStore().logOut()
    return Promise.reject(new Error(t('10602')))
  }
  if ([503, 502].includes(status)) {
    singleMessage.warning(t('10603'))
    return Promise.reject(error)
  }
  if ([504].includes(status)) {
    singleMessage.warning(t('10604'))
    return Promise.reject(error)
  }
  if ([403].includes(status)) {
    singleMessage.warning(t('10605'))
    return Promise.reject(error)
  }
  if ([404].includes(status)) {
    singleMessage.warning(t('10606'))
    return Promise.reject(error)
  }
  if (error.message && error.message.startsWith('timeout of')) {
    singleMessage.error(t('10607'))
    return Promise.reject(error)
  }
  if (error.message && error.message.startsWith('Network Error')) {
    singleMessage.error(t('10608'))
    return Promise.reject(error)
  }
  singleMessage.error(errMsg)
  return Promise.reject(error)
}

const axiosInstance: AxiosInstance = axios.create({
  timeout: 8 * 1000,
  baseURL: import.meta.env.VITE_API_BASE_PATH
})

axiosInstance.interceptors.request.use(requestInterceptors)
axiosInstance.interceptors.response.use(responseInterceptors, resErrorInterceptors)

const service = (config: RequestConfig): Promise<IResponse<any>> => {
  return axiosInstance.request(config)
}

export default service
