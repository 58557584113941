
      const hostname = window.location.hostname;
      const faviconElement = document.getElementById('favicon');
      if (hostname === 'www.nayocloud.com') {
        faviconElement.href = '/Nayo.png';
      } else if (hostname === 'www.micergycloud.com') {
        faviconElement.href = '/Micergy.png';
      } else {
        faviconElement.href = '/favicon.ico';
      }
    